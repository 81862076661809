import { useMemo, useEffect, useState, useCallback } from "react";
import { Badge, Button } from "react-bootstrap";
import { changePriceFormat, numberWithCommas } from "utils/calculators";
import { pageView } from "../../GTM";
import "./style.scss";
import { useCarts } from "_context/shoppingCart/cartProvider";
import ModalProduct from "components/ModalProduct";
import ReactDOM from "react-dom";
import ImageFullLoad from "components/ImageFullLoad";
import Skeleton from "react-loading-skeleton";
import styles from "./index.module.scss";
import { ALLERGENS } from "enumeration/allergies";

const { useTranslation } = require("react-i18next");

const ProductCard = ({
	// handleRemoveCart, handleAddCart, data, isToCart
	data,
	symbol,
	loading,
}) => {
	const { t, i18n } = useTranslation();
	const [show, setShow] = useState(false);
	// const [count, setCount] = useState()

	// useEffect(() => {
	//   if (count >= 0) if (data?.count != count) setCount(data?.count)
	// }, [data])
	const isTranslate = useMemo(() => {
		const objData = {};
		data?.translations?.forEach((x) => (objData[x?.language] = x));

		return objData;
	}, [data]);

	// const decrease = () => { setCount(p => p - 1) }
	// const increase = () => { setCount(p => p + 1) }
	const { addCount, cart, removeItem, addCart } = useCarts();

	const isToCart = cart?.object[data?.id];
	if (isToCart) {
		const shoppingItem = cart?.shoppingCart?.find((o) => o?.data?.id === data?.id);
		// console.log(
		//     "Main DEBUG",
		//     cart,
		//     shoppingItem,
		//     "asdasdas2d"
		// );
		data["count"] = shoppingItem?.count;
	}

	const handleAddCart = useCallback(
		(param) => {
			addCart(param);
			// swal(t("successful"), t("productAddToCart"), "success");
		},
		[addCart]
	);

	const handleRemoveCart = useCallback(
		(param) => {
			removeItem(param);
			// swal(t("successful"), t("removeFromBasket"), "success");
		},
		[removeItem]
	);

	const textTranslate = isTranslate[i18n?.language];
	// console.log({ isTranslate, textTranslate }, i18n?.language);

	useEffect(() => {
		pageView(window?.location?.pathname);
	}, []);

	const limitText = (text, limit) => {
		if (text?.length <= limit) {
			return text;
		}
		return text?.slice(0, limit) + "...";
	};

	let limitedMedicine = false;
	if (data?.medicine && data?.count == 2) limitedMedicine = true;

	return (
		<>
			<div className="col-12 col-md-6 mb-2 cursor-pointer">
				<div className="productCard d-flex flex-row">
					<div className="productCardImageBox" onClick={() => setShow(true)}>
						{/* <img src={data?.images} className="CardImage" alt={data?.name} /> */}
						<ImageFullLoad src={data?.images} className="CardImage" alt={data?.name} />
					</div>
					<div className="card-body ">
						<div onClick={() => setShow(true)}>
							<h4 className="cardText">{loading ? <Skeleton /> : limitText(textTranslate?.name || data?.name, 26)}</h4>

							<p>{loading ? <Skeleton /> : limitText(textTranslate?.shortDesc || data?.shortDesc, 65)}</p>
							{/* <p>{textTranslate?.desc || data?.desc}</p> */}
							<div className="d-flex flex-row mt-2">
								{loading ? (
									<Skeleton height={10} width={50} />
								) : (
									<>
										{/* {data?.oldPrice ? (
											<h4 className="orgPrice">
												{"£"}
												{numberWithCommas(changePriceFormat(data?.oldPrice))}
											</h4>
										) : (
											""
										)} */}
										<h4 className="price">
											{"£"}
											{numberWithCommas(changePriceFormat(data?.price))}
										</h4>
									</>
								)}
							</div>
						</div>
						{data.allergies?.length ? (
							<div className={styles.allergies}>
								{data.allergies?.map((x) => {
									return (
										<div className={styles.allergies_item}>
											<img src={ALLERGENS[x]?.icon} alt={ALLERGENS[x]?.label} className={styles.allergies_icon} />
											{t(ALLERGENS[x]?.label)}
										</div>
									);
								})}
							</div>
						) : (
							""
						)}
						{/* <p className={"allergy_text"}>{t("allergyText")}</p> */}
						{!loading ? (
							isToCart ? (
								<span className="addBasketButtons d-flex align">
									{data?.count > 1 ? (
										<button
											type="button"
											className="left dec quantityButtonMinus comeTop"
											id="adetEksi"
											data-urunid={19}
											onClick={() => {
												// decrease()
												addCount(data, data?.count - 1);
											}}>
											<i className="fa-solid fa-minus" />
										</button>
									) : (
										<button
											type="button"
											className="left dec quantityButtonMinus comeTop"
											id="adetEksi"
											data-urunid={19}
											onClick={() => {
												// decrease()
												handleRemoveCart(data);
											}}>
											<i className="fa-solid fa-minus" />
										</button>
									)}

									<Button className="quantityButton">{data?.count}</Button>
									<button
										type="button"
										className={` right inc quantityButtonPlus`}
										id="adetArti"
										data-urunid={19}
										disabled={limitedMedicine}
										onClick={() => {
											!limitedMedicine && addCount(data);
										}}>
										{" "}
										<i className="fa-solid fa-plus" />
									</button>
								</span>
							) : (
								<Button onClick={() => handleAddCart(data)} className="float-end addBasketButton"></Button>
							)
						) : (
							""
						)}
					</div>
				</div>
			</div>

			<ModalProduct {...{ symbol, show, setShow, handleRemoveCart, handleAddCart, addCount, data, changePriceFormat, textTranslate, isToCart }} />

			{/* <div className="col-12 col-md-6 mb-2 cursor-pointer">
        <div className="productCard d-flex flex-row">
          <div className="" onClick={() => setShow(true)}>
            <img src={data?.images} className="CardImage" alt={data?.name} />
          </div>
          <div className="card-body ">
            <div onClick={() => setShow(true)}>

              <h4 className="cardText">
                {limitText(textTranslate?.name || data?.name, 26)}
              </h4>

              <p>{limitText(textTranslate?.shortDesc || data?.shortDesc, 50)}</p>
              <p>{textTranslate?.desc || data?.desc}</p>
              <div className="d-flex flex-row">
                {data?.oldPrice ? (
                  <h4 className="orgPrice">
                    {"£"}    {changePriceFormat(data?.oldPrice)}
                  </h4>
                ) : (
                  ""
                )}
                <h4 className="price">
                  {"£"}{changePriceFormat(data?.price)}
                </h4>
              </div>
            </div>
            {isToCart ? (
              <span className="float-right d-flex align">
                {data?.count > 1 ? (
                  <button
                    type="button"
                    className="left dec quantityButtonMinus comeTop"
                    id="adetEksi"
                    data-urunid={19}
                    onClick={() => addCount(data, data?.count - 1)}
                  >
                    <i className="fa-solid fa-minus" />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="left dec quantityButtonMinus comeTop"
                    id="adetEksi"
                    data-urunid={19}
                    onClick={() => handleRemoveCart(data)}
                  >
                    <i className="fa-solid fa-minus" />
                  </button>
                )}

                <Button className="quantityButton">{data?.count}</Button>
                <button
                  type="button"
                  className=" right inc quantityButtonPlus"
                  id="adetArti"
                  data-urunid={19}
                  onClick={() => addCount(data)}
                >
                  {" "}
                  <i className="fa-solid fa-plus" />
                </button>
              </span>
            ) : (
              <Button
                onClick={() => handleAddCart(data)}
                className="float-end addBasketButton"
              ></Button>
            )}
          </div>
        </div>
      </div>
  
      <ModalProduct {...{ show, setShow, handleRemoveCart, handleAddCart, addCount, data, changePriceFormat, textTranslate, isToCart }} /> */}
		</>
	);
};

export default ProductCard;
