const baseStrings = {
	translation: {
		home: " الصفحة الرئيسية ",
		menu: " القائمة ",
		opportunity: " خاص ",
		opportunityProducts: " عروض خاصة ",
		payment: " الدفع ",
		back: " عودة ",
		yourName: " الاسم الكامل ",
		roomNumber: " رقم الغرفة ",
		deliveryInfo: " معلومات الضيف ",
		yourDeliveryInfo: " معلومات الضيف ",
		paymentInfo: " معلومات الدفع ",
		productTotal: " إجمالي المنتج ",
		serviceFee: " رسوم الخدمة ",
		total: " المبلغ الإجمالي ",
		online: " الدفع عبر الإنترنت ",
		card: " الدفع عند الاستلام باستخدام البطاقة ",
		myCart: " سلتي ",
		cash: " نقد ",
		note: " ملاحظة ",
		delivery: " التسليم ",
		payment: " الدفع ",
		orderNote: "الرجاء إبلاغنا إذا كان لديك أي حساسيات غذائية، أو احتياجات غذائية خاصة، أو طلبات محددة لفريقنا.",
		completeOrder: " اكمل الطلب ",
		enjoyYourMeal: " استمتع بوجبتك!",
		yourOrderReceived: " تم استلام طلبك ",
		// completeOrder: " اكتمال طلبك ",
		hello: " أهلاً. ",
		uuiError: " يمكنك إدخال ببساطة عن طريق مسح رمز الاستجابة السريعة.",
		successful: " ناجح ",
		productAddToCart: " تمت إضافة المنتج إلى السلة!!",
		addToBasket: " أضف إلى السلة ",
		removeFromBasket: " إزالة من السلة ",
		productTitle: " العناية الشخصية ",
		itemNotFound: " لم يتم العثور على العنصر ",
		completeTheOrder: " يرجى إكمال الطلب ",
		selectThePayment: " الرجاء اختيار نوع الدفع ",
		paymentType: " طريقة الدفع ",
		orderLimitAuthor: " حد الطلب {{مؤلف}} {{عملة}}",
		orderLimit: " حد الطلب ",
		dataNotFound: " لم يتم العثور على بيانات ",
		confirmOrder: " اضغط لإكمال الطلب ",
		exchangeInfo: "إذا دفعت بعملة أجنبية، سيتم إعطاؤك الباقي بالليرة التركية.",
		deliveryInfo: "يرجى التأكد من كتابة اسمك ومعلومات رقم الغرفة بشكل كامل حتى يمكن توصيل طلبك بشكل صحيح.",
		weather: " Weather ",

		totalEuro: " المبلغ الإجمالي (يورو)",
		totalDollar: " المبلغ الإجمالي (دولار)",
		totalTL: " المبلغ الإجمالي (ليرة تركية)",
		diss: " خصم على جميع المنتجات الخاصة لهذا اليوم!",

		continue: " تابع ",
		cashCurrency: " اختر العملة ",
		other: " الفئات الأخرى ",

		// preparing: " يتم إعداد طلبك استمتع بوجبتك...",
		preparing: "شكراً لك! لقد استلمنا طلبك. من فضلك لا تنسَ التحقق من بريدك الوارد للحصول على التفاصيل.",

		notValid: " أدخل الاسم الكامل ورقم الغرفة ",

		deviceError: "يُسمح بحجم شاشة الجهاز اللوحي أو الجوال. حجم الصفحة المسموح به أقل من 768 بكسل.",
		searchHome: " بحث ...",
		notfound: " لم يتم العثور على البيانات ",
		"back-to-menu": "العودة إلى القائمة",
		BankErrorTitle: "فشل الدفع",
		BankErrorDescription: "هناك مشكلة في الدفع. يرجى التحقق من معلومات بطاقتك والمحاولة مرة أخرى",
		minimumCart: "الحد الأدنى : {{author}}",
		modalLegalAgeTitle: "هل أنت كبير بما يكفي لشراء العناصر المقيدة بالعمر؟",
		modalLegalAgeText:
			"العناصر المقيدة بالفئة العمرية (بما في ذلك الكحول والتبغ) ليست معروضة للبيع لأي شخص يقل عمره عن 18 عامًا. قد تحتاج إلى تقديم بطاقة هوية صالحة عند استلام طلبك",
		modalLegalAcceptButton: "نعم، عمري 18 عامًا أو أكثر",
		cancel: "يلغي",
		medicineTitle: "منتجات تخفيف الآلام لها حد 2 لكل عدد",
		phoneNumber: "رقم التليفون",
		phoneNumberDelivery: "رقم الهاتف: خدمة التوصيل لدينا سوف تتصل بك",
		phoneNumberCourier: "رقم الهاتف: سوف يتصل بك ساعي البريد الخاص بنا",

		useDiscountCode: "استخدم رمز الخصم الخاص بك",
		discount: "الخصم",
		discountAuthor: "الخصم ({{author}})",
		products: "المنتجات",
		append: "إلحاق",
		code: "الكود",
		checkAuthor: "تحقق من {{author}}",
		"discount-note": "يمكنك التحقق من رمز الخصم الخاص بك من هذا القسم واستخدامه إذا لزم الأمر",
		"discount-code-details": "الكود الذي قدمته هو {{code}}. اسم رمز الخصم هذا هو {{name}} وقيمته {{amount}}% خصم",
		"back-to-menu": "العودة إلى القائمة",
		BankErrorTitle: "فشل الدفع",
		BankErrorDescription: "هناك مشكلة في الدفع. يرجى التحقق من معلومات بطاقتك والمحاولة مرة أخرى",
		minimumCart: "الحد الأدنى : {{author}}",
		orderNow: "اطلب الآن",
		email: "بريد إلكتروني",
		apply: "Apply",
		allergyText: `قد تحتوي الأطعمة على مسببات حساسية أو تتلامس معها. يرجى ملاحظة أي حساسية تجاه الطعام في "الملاحظات" عند إتمام الشراء`,
		notePlaceholder: "قد تحتوي الأطعمة على مسببات حساسية أو تتلامس معها. يرجى ملاحظة أي حساسية تجاه الطعام!",
	},
};

export const ar = baseStrings;
