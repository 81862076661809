const baseStrings = {
	translation: {
		home: "خانه",
		menu: "منو",
		opportunity: " فرصت ",
		opportunityProducts: "محصولات فرصت ",
		payment: "پرداخت",
		back: "Back",
		yourName: "اسم شما ",
		roomNumber: "شماره اتاق ",
		deliveryInfo: "اطلاعات تحویل ",
		yourDeliveryInfo: "اطلاعات تحویل شما",
		paymentInfo: "اطلاعات پرداخت ",
		productTotal: "مجموع محصول ",
		serviceFee: "هزینه خدمات ",
		total: "جمع",
		online: "برخط",
		card: "کارت",
		myCart: "کارت من",
		cash: "پول نقد ",
		note: "توجه داشته باشید ",
		delivery: "تحویل",
		payment: "پرداخت",
		orderNote: "یادداشت سفارش خود را وارد کنید ",
		completeOrder: "سفارش کامل ",
		enjoyYourMeal: "نوش جان!",
		yourOrderReceived: "سفارش شما دریافت شد",
		// completeOrder: "سفارش شما تکمیل شده است",
		hello: "سلام",
		uuiError: "فقط با اسکن کد qr می توانید وارد شوید ",
		successful: "موفقیت آمیز ",
		productAddToCart: "محصول به سبد خرید اضافه شد!",
		addToBasket: "به سبد اضافه کن",
		removeFromBasket: "حذف از سبد",
		productTitle: "عنوان محصول",
		itemNotFound: "مورد یافت نشد",
		completeTheOrder: "تکمیل سفارش",
		selectThePayment: "پرداخت را انتخاب کنید",
		paymentType: "نوع پرداخت",
		orderLimitAuthor: "سفارش محدود نویسنده {{author}} {{currency}}",
		orderLimit: "حد سفارش",
		dataNotFound: "داده یافت نشد",
		confirmOrder: "برای تکمیل سفارش فشار دهید",
		exchangeInfo: "اگر با ارز خارجی پرداخت کنید، تبدیل شما به لیره ترکی انجام می‌شود.",
		continue: "ادامه",
		cashCurrency: "واحد پول را انتخاب کنید",
		other: "سایر کتگوری ها",
		// preparing: "سفارش شما در حال آماده شدن است نوش جان...",
		preparing: "ممنون! سفارش شما را دریافت کرده‌ایم. لطفاً فراموش نکنید صندوق ورودی خود را برای جزئیات بررسی کنید.",

		notValid: "نام و شماره اتاق را وارد کنید",

		searchHome: "...جستجو",
		notfound: "داده یافت نشد",
		"back-to-menu": "بازگشت به منو",
		bankErrorTitle: "پرداخت انجام نشد",
		bankErrorDescription: "مشکلی در پرداخت وجود دارد. لطفاً اطلاعات کارت خود را بررسی کنید و دوباره امتحان کنید",
		minimumCart: "حداقل : {{author}}",
		modalLegalAgeTitle: "آیا به اندازه کافی برای خرید اقلام دارای محدودیت سنی سن دارید؟",
		modalLegalAgeText:
			"اقلام دارای محدودیت سنی (از جمله الکل و تنباکو) برای افراد زیر 18 سال قابل فروش نیستند. ممکن است لازم باشد پس از دریافت سفارش خود یک شناسه معتبر ارائه دهید.",
		modalLegalAcceptButton: "بله، من 18 سال یا بیشتر هستم",
		cancel: "لغو",
		medicineTitle: "محصولات تسکین درد دارای محدودیت 2 در هر تعداد هستند",
		phoneNumber: "شماره تلفن",
		phoneNumberDelivery: "شماره تلفن : پیک ما با شما تماس می گیرد",
		phoneNumberCourier: "شماره تلفن : پیک ما با شما تماس خواهد گرفت",
		useDiscountCode: "از کد تخفیف خود استفاده کنید",
		discount: "تخفیف",
		discountAuthor: "تخفیف ({{author}})",
		products: "محصولات",
		append: "افزودن",
		code: "کد",
		checkAuthor: "چک {{author}}",
		"discount-note":
			"شما می توانید کد تخفیف خود را از این بخش بررسی کرده و در صورت لزوم از آن استفاده کنیدYou can check your discount code from this section and use it if necessary",
		"discount-code-details": "کدی که ارائه کردید {{code}} است. نام این کد تخفیف {{name}} است و دارای ارزش {{amount}}% تخفیف می باشد",
		"back-to-menu": "بازگشت به منو",
		bankErrorTitle: "پرداخت انجام نشد",
		bankErrorDescription: "مشکلی در پرداخت وجود دارد. لطفاً اطلاعات کارت خود را بررسی کنید و دوباره امتحان کنید",
		minimumCart: "حداقل : {{author}}",
		orderNow: "اکنون سفارش دهید",
		AppointmentDaySelection: "انتخاب روز قرار",
		email: "ایمیل",
		apply: "اعمال کنید",
		allergyText: `ممکن است غذاها حاوی آلرژن‌ها باشند یا با آن‌ها تماس داشته باشند. لطفاً هرگونه حساسیت غذایی را در قسمت "یادداشت‌ها" در زمان پرداخت ذکر کنید`,
		notePlaceholder: "ممکن است غذاها حاوی آلرژن‌ها باشند یا با آن‌ها تماس داشته باشند. لطفاً هرگونه حساسیت غذایی را یادداشت کنید!",
	},
};

export const fa = baseStrings;
