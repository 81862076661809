import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styles from "./product.module.scss";
import { ALLERGENS } from "enumeration/allergies";
import { Alert } from "react-bootstrap";
const { useTranslation } = require("react-i18next");

function ModalProduct({ show, setShow, handleRemoveCart, handleAddCart, addCount, data, changePriceFormat, textTranslate, isToCart }) {
	const { t, i18n } = useTranslation();

	// console.log({ data });
	// const allergies = ["celery", "lupin", "milk", "molluscs", "eggs"]
	let limitedMedicine = false;
	if (data?.medicine && data?.count == 2) limitedMedicine = true;

	return (
		<>
			<Modal
				show={show}
				onHide={() => setShow(false)}
				centered={false}
				style={{
					top: "0",
				}}>
				<Modal.Body className={styles.modalBody}>
					<div className={styles.close} onClick={() => setShow(false)}>
						x
					</div>
					<div className="d-flex flex-column">
						<div className={styles.image}>
							<img src={data?.images} className="w-100" alt={data?.name} />
						</div>
						<div className={[styles.content, "border-top"].join(" ")}>
							<div>
								<div className="px-3 my-3">
									<h2>{textTranslate?.name || data?.name}</h2>
									<p>{textTranslate?.shortDesc || data?.shortDesc}</p>
									{/* <p>{textTranslate?.desc || data?.desc}</p> */}
								</div>

								{data?.medicine ? (
									<div className={[styles.medicine, "border-top border-bottom p-3 mb-2"].join(" ")}>
										<div className="d-flex align-items-center ">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="16"
												height="16"
												fill="currentColor"
												className="bi bi-exclamation-triangle"
												viewBox="0 0 16 16">
												<path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z" />
												<path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
											</svg>
											<div className="ml-2">{t("medicineTitle")}</div>
										</div>
									</div>
								) : (
									""
								)}

								<div className="px-3 my-3">
									<div className="d-flex flex-row align-items-center mt-2">
										{/* {data?.oldPrice ? (
                      <h4 className="orgPrice mr-2">
                        {"£"}   {changePriceFormat(data?.oldPrice)}
                      </h4>
                    ) : (
                      ""
                    )} */}

										<h4 className="price ">
											{"£"} {changePriceFormat(data?.price)}
										</h4>
									</div>
								</div>
							</div>

							<p className={"allergy_text px-3"}>{t("allergyText")}</p>

							{data?.allergies?.length ? (
								<div className={[styles.allergies, "px-3 my-3"].join(" ")}>
									{data?.allergies?.map((x) => {
										return (
											<div className={styles.allergies_item}>
												<img src={ALLERGENS[x]?.icon} alt={ALLERGENS[x]?.label} className={styles.allergies_icon} />
												{t(ALLERGENS[x]?.label)}
											</div>
										);
									})}
								</div>
							) : (
								""
							)}

							<div className={[styles.parentElement, "px-3 my-3"].join(" ")}>
								<span
									className={`d-flex align-items-center justify-content-center ${styles.element} ${styles.button} ${styles.changeCartButtonsParent} ${
										isToCart ? styles.show : styles.hide
									}`}>
									{data?.count > 1 ? (
										<button
											type="button"
											className=" dec quantityButtonMinus comeTop"
											id="adetEksi"
											data-urunid={19}
											onClick={() => addCount(data, data?.count - 1)}>
											<i className="fa-solid fa-minus" />
										</button>
									) : (
										<button type="button" className=" dec quantityButtonMinus comeTop" id="adetEksi" data-urunid={19} onClick={() => handleRemoveCart(data)}>
											<i className="fa-solid fa-minus" />
										</button>
									)}

									<Button className="quantityButton">{data?.count}</Button>
									<button
										type="button"
										className="  inc quantityButtonPlus"
										id="adetArti"
										data-urunid={19}
										// onClick={() => addCount(data)}
										disabled={limitedMedicine}
										onClick={() => {
											!limitedMedicine && addCount(data);
										}}>
										{" "}
										<i className="fa-solid fa-plus" />
									</button>
								</span>
								<div className={`d-grid gap-2  ${styles.element}  ${!isToCart ? styles.show : styles.hide}`} onClick={() => handleAddCart(data)}>
									<Button variant="success" size="md">
										{t("addToBasket")}
									</Button>
								</div>
								{/* )} */}
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default ModalProduct;
