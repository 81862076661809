import React from "react";
import PropTypes from "prop-types";
import "./style.scss"; // Import the CSS file for styling
import { Link } from "react-router-dom";
const { useTranslation } = require("react-i18next");

const PageNavbar = ({ text, path }) => {
	const { t, i18n } = useTranslation();
	return (
		<div className="pageNavbar">
			<Link to={path} className="backLink gap-2">
				<i className="fa-solid fa-angle-left backIcon" />
				<h4 className="deliveryText mb-0">{t(text)}</h4>
			</Link>
		</div>
	);
};

PageNavbar.propTypes = {
	text: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
};

export default PageNavbar;
