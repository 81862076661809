import { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import RemoveNullObjectValue from "lib/RemoveNullObjectValue";
import useQueryString from "hooks/useQueryString";
import { t } from "i18next";
import { useUuid } from "_context/uuid/uuidProvider";
import * as api from "../../api/api";
import { useQuery } from "react-query";
import { useCarts } from "_context/shoppingCart/cartProvider";
import SearchBarCard from "components/SearchBarCard";
import useClickTarget from "hooks/useClickTarget";
import Icons from "reuse/Icons";
import notfoundImage from "assets/notfound.png";

const SearchBar = ({ inputProps, reference }) => {
	const [search, setSearch] = useState("");
	const [searchText, setSearchText] = useState("");
	const [showPopup, setShowPopup] = useState(false);
	const { addCart, removeItem, cart } = useCarts();

	const dropdownRef = useRef(null);

	// const target = useClickTarget(dropdownRef, showPopup, setShowPopup);

	const handleAddCart = (param) => {
		// console.log({ param });
		addCart(param);
	};

	const handleRemoveCart = (param) => {
		removeItem(param);
	};

	const { data: uuid } = useUuid();
	let {
		data: { result } = {},
		isError,
		error,
		isLoading,
		isFetching,
	} = useQuery(["getHotelMenus", search], () => api.getHotelMenus(uuid, search), {
		enabled: !!uuid && !!search,
		staleTime: 100000,
	});
	console.log({ result, search });

	const inputInterval = useRef();
	const ref = useRef();
	reference = ref;
	// const location = useLocation();

	// const changeUrl = (params) => ({
	//     pathname: location.pathname,
	//     search: new URLSearchParams(RemoveNullObjectValue({ ...queryString, ...params })).toString(),
	// });

	// const navigate = useNavigate();
	// const navigateUrl = (obj) => navigate(changeUrl({ ...obj, page: 1 }));

	const handleChange = (e) => {
		const value = e.target.value;
		setSearchText(value);
		clearTimeout(inputInterval.current);
		inputInterval.current = setTimeout(() => {
			setSearch(value);
		}, 1000);
	};

	useEffect(() => {
		if (search) setShowPopup(true);
	}, [search]);

	const onKeyDown = (e) => {
		console.log({ e });
		inputProps?.onKeyDown?.(e);
		if (e.keyCode === 13) {
			setShowPopup(false);
		}
	};

	return (
		<div className={styles.container}>
			<div className={[styles.backdrop, showPopup ? styles.show : " "].join(" ")} onClick={() => setShowPopup()} />

			<div className={styles.searchBox}>
				<Icons.Search />
				<input
					placeholder={t("searchHome")}
					type="text"
					className={["form-control border-success", styles.input, showPopup && styles.open].join(" ")}
					name="search-bar"
					onChange={handleChange}
					ref={ref}
					// defaultValue={search}
					{...(inputProps && inputProps)}
					onKeyDown={onKeyDown}
					value={searchText}
				/>
				{search ? (
					<button
						type="button"
						className={styles.closeIcon}
						aria-label="Close"
						onClick={() => {
							setShowPopup(false);
							setSearchText("");
						}}>
						<span aria-hidden="true">&times;</span>
					</button>
				) : (
					""
				)}
			</div>

			<div className={styles.searchBarContainer}>
				{showPopup ? (
					<div className={styles.searchBarBox} ref={dropdownRef}>
						{/* <div>
                            <div>{t("searchAuthor", { author: search })}</div>

                        </div> */}
						<div className=" g-4 mt-3 px-2 ">
							{
								// !result?.length
								//  && isFetching ?

								// <div className={styles.notfound}>{t("loading")}</div> :
								!result?.length && !isFetching ? (
									<div className={styles.notfound}>
										<div className={styles.notfoundImage}>
											<img src={notfoundImage} />
										</div>
										<div className={styles.notfoundText}>{t("notfound")}</div>
									</div>
								) : (
									(result || new Array(10).fill())?.map((x) => {
										const { product, id, ...other } = x || {};
										const params = {
											menuId: id,
											id,
											...product,
											...other,
										};
										const isToCart = cart?.object[params?.id];
										if (isToCart) {
											const shoppingItem = cart?.shoppingCart?.find((o) => o?.data?.id === params?.id);

											params["count"] = shoppingItem?.count;
										}

										return (
											<div className="row g-4  mt-1">
												<SearchBarCard
													key={id}
													handleRemoveCart={handleRemoveCart}
													handleAddCart={handleAddCart}
													isToCart={isToCart}
													data={params}
													loading={!x}
												/>
											</div>
										);
									})
								)
							}
						</div>
					</div>
				) : (
					""
				)}
			</div>
		</div>
	);
};

export default SearchBar;

// import { useEffect, useRef, useState } from "react";
// import styles from "./index.module.scss"
// import { useLocation, useNavigate } from "react-router-dom";
// import RemoveNullObjectValue from "lib/RemoveNullObjectValue";
// import useQueryString from "hooks/useQueryString";
// import { t } from "i18next";
// import { useUuid } from "_context/uuid/uuidProvider";
// import * as api from "../../api/api";
// import { useQuery } from "react-query";
// import { useCarts } from "_context/shoppingCart/cartProvider";
// import SearchBarCard from 'components/SearchBarCard';
// import useClickTarget from "hooks/useClickTarget";
// import Icons from "reuse/Icons";
// import { Spinner } from "react-bootstrap";

// const SearchBar = ({ inputProps, reference }) => {

//     const [search, setSearch] = useState('')
//     const [showPopup, setShowPopup] = useState(false)
//     const { addCart, removeItem, cart } = useCarts();

//     const dropdownRef = useRef(null);

//     const target = useClickTarget(dropdownRef, showPopup, setShowPopup);

//     const handleAddCart = (param) => {
//         addCart(param);
//     };

//     const handleRemoveCart = (param) => {
//         removeItem(param);
//     };

//     const { data: uuid } = useUuid();
//     let {
//         data: { result } = {},
//         isError,
//         error,
//         isLoading,
//         isFetching,
//     } = useQuery(
//         ["getHotelMenus", search],
//         () => api.getHotelMenus(uuid, search),
//         {
//             enabled: (!!uuid && !!search),
//             staleTime: 100000,

//         }
//     );
//     console.log({ result, search });

//     const inputInterval = useRef()
//     const ref = useRef()
//     reference = ref
//     // const location = useLocation();

//     // const changeUrl = (params) => ({
//     //     pathname: location.pathname,
//     //     search: new URLSearchParams(RemoveNullObjectValue({ ...queryString, ...params })).toString(),
//     // });

//     // const navigate = useNavigate();
//     // const navigateUrl = (obj) => navigate(changeUrl({ ...obj, page: 1 }));

//     const handleChange = (e) => {
//         clearTimeout(inputInterval.current)
//         const value = e.target.value;
//         inputInterval.current = setTimeout(() => {
//             setSearch(value)
//         }, 1000);
//     }

//     useEffect(() => {
//         if (search) setShowPopup(true)
//     }, [search])

//     const onKeyDown = (e) => {
//         console.log({ e });
//         inputProps?.onKeyDown?.(e)
//         if (e.keyCode === 13) {
//             setShowPopup(false)
//         }
//     }

//     return (
//         <div className={styles.container}>
//             <div className={styles.searchBox}>
//                 <Icons.Search />
//                 <input
//                     placeholder={t("searchHome")}
//                     type="text"
//                     className={["form-control border-success", styles.input].join(" ")}
//                     name="search"
//                     onChange={handleChange}
//                     ref={ref}
//                     defaultValue={search}
//                     {...(inputProps && inputProps)}
//                     onKeyDown={onKeyDown}
//                 />
//             </div>

//             <div className={styles.searchBarContainer} >

//                 {showPopup ?
//                     <div className={styles.searchBarBox} ref={dropdownRef}>
//                         <div className="">
//                             <div>{t("searchLabel")} : {search}</div>
//                             <button type="button" className={styles.closeIcon} aria-label="Close" onClick={() => setShowPopup(false)}>
//                                 <span aria-hidden="true">&times;</span>
//                             </button>
//                         </div>

//                         <div className=" g-4 mt-4 px-2">
//                             {
//                                 !result?.length && isFetching ?
//                                     <div className={styles.noteBox}>
//                                         <div className={styles.notfound}>
//                                             {/* {t("loading")} */}
//                                             <div className={styles.noteWarp}>
//                                                 <Spinner animation="border" />
//                                                 <div className={styles.notfoundText}>{t("searchText")}</div>
//                                             </div>
//                                         </div>
//                                     </div> :
//                                     !result?.length && !isFetching ?
//                                         <div className={styles.noteBox}>
//                                             <div className={styles.noteWarp}>
//                                                 <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-emoji-frown" viewBox="0 0 16 16">
//                                                     <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
//                                                     <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
//                                                 </svg>
//                                                 <div className={styles.notfoundTitle}>{t("notfoundTitle")}</div>
//                                                 <div className={styles.notfoundText}>{t("notfoundText")}</div>
//                                             </div>
//                                         </div>
//                                         :

//                                         result?.map((x) => {
//                                             const { product, id, ...other } = x;
//                                             const params = {
//                                                 menuId: id,
//                                                 ...product,
//                                                 ...other,
//                                             };
//                                             const isToCart = cart?.object[params?.id];
//                                             if (isToCart) {
//                                                 const shoppingItem = cart?.shoppingCart?.find(
//                                                     (o) => o?.data?.id === params?.id
//                                                 );

//                                                 params["count"] = shoppingItem?.count;
//                                             }

//                                             return (

//                                                 <div className="row g-4  mt-1">
//                                                     <SearchBarCard
//                                                         key={id}
//                                                         handleRemoveCart={handleRemoveCart}
//                                                         handleAddCart={handleAddCart}
//                                                         isToCart={isToCart}
//                                                         data={params}
//                                                     />
//                                                 </div>
//                                             )
//                                         })
//                             }
//                         </div>
//                     </div>
//                     : ""
//                 }
//             </div>
//         </div>
//     )
// }

// export default SearchBar;
